import { useState, useEffect, useRef } from 'react';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

export const useNotificationStream = (enabled: boolean) => {
  const [notification, setNotification] = useState<any>({});
  const eventSourceRef = useRef<EventSource | null>(null);
  const reconnectTimerRef = useRef<NodeJS.Timeout | null>(null);
  const access_token = storage.getToken();

  const teamData = localStorage.getItem('flowscale_hub_team');
  const xTeamIdFromLocalStorage = teamData ? JSON.parse(teamData)._id : null;
  const xTeamId = xTeamIdFromLocalStorage;

  const establishSSEConnection = () => {
    if (!enabled || !xTeamId || !access_token) return;

    if (eventSourceRef.current) eventSourceRef.current.close();

    const url = `${API_URL}/v1/notifications/stream?access_token=${access_token}&x_team=${xTeamId}`;
    const newEventSource = new EventSource(url);
    eventSourceRef.current = newEventSource;

    newEventSource.onopen = () => {
      if (reconnectTimerRef.current) {
        clearTimeout(reconnectTimerRef.current);
        reconnectTimerRef.current = null;
      }
    };

    newEventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setNotification(data);
    };

    newEventSource.onerror = () => {
      newEventSource.close();
      reconnectTimerRef.current = setTimeout(establishSSEConnection, 5000);
    };
  };

  useEffect(() => {
    if (enabled) {
      establishSSEConnection();
    } else {
      if (eventSourceRef.current) eventSourceRef.current.close();
      if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
    }
    return () => {
      if (eventSourceRef.current) eventSourceRef.current.close();
      if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
    };
  }, [enabled, access_token, xTeamId]);

  const terminateSSEConnection = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
  };

  return { notification, establishSSEConnection, terminateSSEConnection };
};
