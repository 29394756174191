import React, { useState, useEffect, useRef, UIEvent, useMemo } from 'react';
import { ActionList, ActionMenu, Box, Button, IconButton, Text, ProgressBar } from '@primer/react';
import { Bell, BellSlash, Checks, Warning, WarningCircle, X, Spinner } from 'phosphor-react';
import { InformationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import CustomBlankslate from '@/components/Elements/CustomBlankSlate/CustomBlankSlate';
import { useGetAllNotifications } from '../api/getNotifications';
import { useReadAllNotification } from '../api/patchReadAllNotifications';
import { useReadNotification } from '../api/patchReadNotification';
import { useStreamNotificationStore } from '../stores/useStreamNotificationStore';
import { useProgressStore } from '../stores/useProgressStore';
import { showNotificationDate } from '@/utils/format';
import { useQueryClient } from 'react-query';

// Define a type for API/SSE notifications.
type Notification = {
  id: string;
  message: string;
  read: boolean;
  created_at: string;
  level?: 'info' | 'success' | 'warning' | 'error';
};

interface NotificationDropdownProps {}

const levelIcons: { [key in NonNullable<any>]: JSX.Element } = {
  info: <InformationCircleIcon className="h-5 w-5 text-blue-500" />,
  success: <CheckCircleIcon className="h-5 w-5 text-green-500" />,
  warning: <Warning className="h-5 w-5 text-yellow-500" />,
  error: <WarningCircle className="h-5 w-5 text-red-500" />,
};

export const NotificationDropdown: React.FC<NotificationDropdownProps> = () => {
  // State for API notifications.
  const [apiNotifications, setApiNotifications] = useState<Notification[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const limit = 25;
  const [showLoadButton, setShowLoadButton] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  // Get team id from localStorage.
  const teamData = localStorage.getItem('flowscale_hub_team');
  const xTeamId = teamData ? JSON.parse(teamData)._id : null;
  const queryClient = useQueryClient();

  // Get real-time notifications from the SSE store.
  const {
    notifications: sseNotifications,
    removeNotification: removeSseNotification,
    clearNotifications: clearSseNotifications,
  } = useStreamNotificationStore();

  // Get progress notifications from the progress store.
  const { notifications: progressNotifications } = useProgressStore();

  // Fetch older notifications from the API using react-query.
  const { data, isLoading, error, refetch, isRefetching } = useGetAllNotifications(
    xTeamId,
    skip,
    limit
  );

  // Invalidate notifications when dropdownOpen changes (open or closed)
  useEffect(() => {
    if (dropdownOpen) {
      queryClient.invalidateQueries(['notifications']);
    }
  }, [dropdownOpen, queryClient]);

  // Update API notifications state when new data is received.
  useEffect(() => {
    if (data && data.data) {
      // Transform API data: only include items with a valid _id and include the level field.
      const transformedData: Notification[] = data.data
        .filter((n: any) => n._id)
        .map((n: any) => ({
          id: n._id,
          message: n.message,
          read: n.read,
          created_at: n.created_at,
          level: n.level, // e.g. "info", "success", etc.
        }));
      if (skip === 0) {
        setApiNotifications(transformedData);
      } else {
        setApiNotifications((prev) => [...prev, ...transformedData]);
      }
    }
  }, [data, skip]);

  // Filter out any SSE notifications that do not have a valid id.
  const validSseNotifications = useMemo(() => {
    return sseNotifications.filter((n: any) => n.id);
  }, [sseNotifications]);

  // Sort SSE and API notifications separately so that SSE notifications appear at the top.
  const sortedSseNotifications = useMemo(() => {
    return [...validSseNotifications].sort(
      (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, [validSseNotifications]);

  const sortedApiNotifications = useMemo(() => {
    return [...apiNotifications].sort(
      (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, [apiNotifications]);

  // Combine notifications:
  // 1. All progress notifications (from the progress store) are placed at the top.
  // 2. Then, all SSE notifications.
  // 3. Then, API notifications that are not already in SSE.
  const combinedNotifications = useMemo(() => {
    const sseIds = new Set(sortedSseNotifications.map((n) => n.id));
    const filteredApi = sortedApiNotifications.filter((n) => !sseIds.has(n.id));
    return [...progressNotifications, ...sortedSseNotifications, ...filteredApi];
  }, [progressNotifications, sortedSseNotifications, sortedApiNotifications]);

  // Initialize mutations.
  const { mutate: markAllMutation } = useReadAllNotification();
  const { mutate: markOneMutation } = useReadNotification();

  // Handler to mark all notifications as read.
  const markAllAsRead = () => {
    markAllMutation(undefined, {
      onSuccess: () => {
        setApiNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
        clearSseNotifications();
      },
    });
  };

  // Handler to mark a single notification as read.
  const handleNotificationSelect = (id: string) => {
    // Find the notification in the combined list.
    const notification: any = combinedNotifications.find((n) => n.id === id);
    // For progress notifications, if progress is less than 100, do nothing.
    if (notification?.progress !== undefined && notification.progress < 100) return;
    // Proceed with marking as read if not already read.
    if (notification?.read) return;
    markOneMutation(
      { notification_id: id },
      {
        onSuccess: () => {
          setApiNotifications((prev) => prev.map((n) => (n.id === id ? { ...n, read: true } : n)));
          removeSseNotification(id);
        },
      }
    );
  };

  // Determine if there are any unread notifications.
  const unreadExists = combinedNotifications.some((n: any) =>
    n.progress !== undefined ? n.progress < 100 : !n.read
  );
  const unreadCount = combinedNotifications.filter((n: any) =>
    n.progress !== undefined ? n.progress < 100 : !n.read
  ).length;

  // Handle scrolling to show/hide the "Load older" button.
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    setShowLoadButton(scrollTop + clientHeight >= scrollHeight - 10);
  };

  // Increase skip to load older notifications.
  const loadOlderNotifications = () => {
    setSkip((prevSkip) => prevSkip + 10);
    setShowLoadButton(false);
  };

  // Helper: Render icon based on level. For progress notifications, no level icon is shown.
  const renderIcon = (notification: any) => {
    if (notification.progress !== undefined) return null;
    if (notification.level && levelIcons[notification.level]) {
      return levelIcons[notification.level];
    }
    // Fallback icon:
    return <InformationCircleIcon className="h-5 w-5 text-gray-500" />;
  };

  return (
    <ActionMenu onOpenChange={(open: boolean) => setDropdownOpen(open)} open={dropdownOpen}>
      <ActionMenu.Anchor>
        <button
          ref={triggerRef}
          onClick={() => setDropdownOpen((prev) => !prev)}
          className="flex items-center"
          aria-label="Open menu"
        >
          <div className="relative">
            <Bell size={24} weight={dropdownOpen ? 'fill' : 'regular'} />
            {!dropdownOpen && unreadExists && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 8,
                  height: 8,
                  backgroundColor: 'green',
                  borderRadius: '50%',
                }}
              />
            )}
          </div>
        </button>
      </ActionMenu.Anchor>
      <ActionMenu.Overlay
        sx={{ borderRadius: '4px', padding: 2, backgroundColor: '#16191D' }}
        width="large"
        height="large"
      >
        <Box className="flex flex-col h-full justify-between">
          <Box className="flex items-center justify-between text-white">
            <Text>Notifications ({unreadCount})</Text>
            <Box className="flex items-center">
              <Button
                leadingVisual={Checks}
                variant={unreadExists ? 'primary' : 'invisible'}
                onClick={markAllAsRead}
                size="small"
                disabled={combinedNotifications.length === 0 || !unreadExists}
              >
                Mark all as read
              </Button>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDropdownOpen(false);
                }}
                icon={X}
                aria-label="Close"
                tooltipDirection="n"
                variant="invisible"
              />
            </Box>
          </Box>
          <ActionList.Divider />
          <Box
            ref={scrollContainerRef}
            onScroll={handleScroll}
            className="relative max-h-[350px] overflow-y-auto"
          >
            {(isLoading || isRefetching) && skip === 0 ? (
              <Box className="flex items-center gap-2 justify-center h-[350px]">
                <Spinner className="animate-spin" size={18} />
                Loading...
              </Box>
            ) : error && skip === 0 ? (
              <Box className="flex items-center justify-center h-[350px]">
                <CustomBlankslate
                  heading="Something went wrong!"
                  icon={<BellSlash size={42} />}
                  description="We are unable to get notifications. Please try again later."
                />
              </Box>
            ) : combinedNotifications.length === 0 ? (
              <Box className="flex items-center justify-center h-[350px]">
                <CustomBlankslate
                  heading="No notification"
                  icon={<BellSlash size={42} />}
                  description="There are no notifications yet."
                />
              </Box>
            ) : (
              combinedNotifications.map((notification: any) => (
                <Box
                  key={notification.id}
                  className="flex w-full items-center gap-2 cursor-pointer text-sm rounded-lg py-2 px-3 hover:bg-[#303131]"
                  onClick={() => handleNotificationSelect(notification.id)}
                >
                  <div>{notification.progress !== undefined ? null : renderIcon(notification)}</div>
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <span>{notification?.message?.replace(/:\s*\d+\.?\d*%/, '')}</span>
                    <span className="text-gray-500 text-xs ml-auto">
                      {notification.created_at ? showNotificationDate(notification.created_at) : ''}
                    </span>
                    {notification.progress !== undefined && (
                      <Box className="w-full mt-2">
                        <ProgressBar
                          progress={notification.progress}
                          bg="#187DDB"
                          aria-label="Upload progress"
                          animated
                        />
                      </Box>
                    )}
                  </div>
                  {!notification.read && (
                    <Box
                      sx={{
                        display: 'inline-block',
                        width: 8,
                        height: 8,
                        backgroundColor: 'green',
                        borderRadius: '50%',
                        ml: 1,
                      }}
                    />
                  )}
                </Box>
              ))
            )}
            {showLoadButton && data && data.data && data.data.length === limit && (
              <Box sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
                <Button onClick={loadOlderNotifications}>Load older notifications</Button>
              </Box>
            )}
          </Box>
        </Box>
      </ActionMenu.Overlay>
    </ActionMenu>
  );
};
