import React, { CSSProperties, FC, ReactNode, useState } from 'react';

interface CustomButtonProps {
  leadingVisual?: FC<{ className?: string; style?: CSSProperties }>;
  trailingIcon?: FC<{ className?: string; style?: CSSProperties }>;
  children: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  hoverStyle?: CSSProperties;
  disabled?: boolean;
  title?: any;
}

const CustomButton: FC<CustomButtonProps> = ({
  leadingVisual: LeadingVisual,
  trailingIcon: TrailingIcon,
  children,
  onClick,
  style = {},
  iconStyle = {},
  hoverStyle = {
    color: '#C4C4CA',
    border: '1px solid #636C76',
    backgroundColor: '#16191D',
  },
  disabled = false,
  title,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultStyle: CSSProperties = {
    borderRadius: '24px',
    fontSize: '16px',
    fontWeight: '500',
    padding: '4px 11px',
    backgroundColor: disabled ? '#16191D' : '#fff',
    color: '#16191D',
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.5 : 1,
  };

  const defaultIconStyle: CSSProperties = {
    margin: '0 4px 0 0',
  };

  const combinedStyle = isHovered
    ? { ...defaultStyle, ...hoverStyle }
    : { ...defaultStyle, ...style };
  const combinedIconStyle = { ...defaultIconStyle, ...iconStyle };

  return (
    <button
      onClick={onClick}
      title={title}
      style={combinedStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled} // Pass disabled prop to button element
    >
      {LeadingVisual && <LeadingVisual className="icon" style={combinedIconStyle} />}
      <span className="mx-1">{children}</span>
      {TrailingIcon && <TrailingIcon className="icon" style={combinedIconStyle} />}
    </button>
  );
};

export default CustomButton;
