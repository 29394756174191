import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

export const getRepoDetailsById = async (git_repo_id: string): Promise<any> => {
  const access_token = storage.getToken();
  const GITHUB_API_URL = 'https://api.github.com';

  if (!access_token) {
    return;
  }
  try {
    if (git_repo_id) {
      const response = await axios.get(
        `${GITHUB_API_URL}/repositories/${git_repo_id}?a=${Math.random()}`,
        {
          headers: {
            authorization: `${access_token}`,
          },
        }
      );
      return response;
    }
  } catch (error) {
    // console.error('Error getting repository details:', error);
    // throw error;
  }
};

export const useGetRepoDetailsById = (git_repo_id: string, config = {}) => {
  const { addNotification } = useNotificationStore();

  return useQuery<any, Error>(
    ['repoDetailsById', git_repo_id],
    () => getRepoDetailsById(git_repo_id),
    {
      onError: (error) => {
        addNotification({
          type: 'error',
          title: 'Failed to fetch repository details',
          message: error.message,
        });
      },
      onSuccess: (data) => {},
      ...config,
    }
  );
};
