import React from 'react';
import { ActionMenu, Avatar, Box, Label } from '@primer/react';
import { Check, Gear, Spinner } from 'phosphor-react';
import storage from '@/utils/storage';
import { generateIdenticon } from '@/utils/randomLogos';
import { useNavigate } from 'react-router-dom';
import { useGetTeam } from '../api/getTeam';

type TeamsDropdownProps = {
  teams: any;
  isTeamsLoading: boolean;
};

export const TeamsDropdown: React.FC<TeamsDropdownProps> = ({ teams, isTeamsLoading }) => {
  const { data: selectedTeam, isLoading: isSelectedTeamLoading } = useGetTeam();
  const { setTeam } = storage;
  const navigate = useNavigate();

  const seletedPlanName =
    selectedTeam?.data?.plan === 'build'
      ? 'Build'
      : selectedTeam?.data?.plan === 'free'
      ? 'Free'
      : selectedTeam?.data?.free_trial_status === 'active' && selectedTeam?.data?.plan === 'pro'
      ? 'Free Trial'
      : selectedTeam?.data?.plan === 'pro'
      ? 'Deploy'
      : 'Enterprise';

  // Create a sorted list of teams with the user's own team at the top
  const sortedTeams = teams?.data
    ? [...teams.data].sort((a, b) => {
        if (a._id === selectedTeam?.data?._id) return -1;
        if (b._id === selectedTeam?.data?._id) return 1;
        return 0;
      })
    : [];

  return (
    <ActionMenu>
      <ActionMenu.Button
        disabled={false}
        alignContent="center"
        sx={{
          border: `1px solid #464b51`,
          borderRadius: '6px',
          fontSize: '12px',
          backgroundColor:
            'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
        }}
      >
        {isTeamsLoading || isSelectedTeamLoading ? (
          <Spinner size={18} className="animate-spin" />
        ) : (
          <Box className="flex items-center gap-1">
            <span className="flex items-center gap-1">
              <Avatar
                className="mr-2"
                src={
                  selectedTeam?.data?.logo
                    ? selectedTeam?.data?.logo
                    : generateIdenticon(selectedTeam?.data?.name)
                }
              />
              <span className="text-sm">{selectedTeam?.data?.name}</span>
              <Label
                className="ml-2"
                variant={
                  selectedTeam?.data?.plan && selectedTeam?.data?.plan !== 'free'
                    ? 'success'
                    : !selectedTeam?.data?.plan
                    ? 'danger'
                    : 'primary'
                }
              >
                {selectedTeam?.data?.plan ? seletedPlanName?.toUpperCase() : 'INACTIVE'}
              </Label>
            </span>
          </Box>
        )}
      </ActionMenu.Button>
      <ActionMenu.Overlay
        width="medium"
        height="fit-content"
        align="end"
        sx={{
          borderRadius: '0.25rem',
        }}
      >
        <Box className="flex flex-col">
          <span className="text-sm border-b border-[#636C76] px-5 py-2">Switch Organization:</span>
          <Box className="flex flex-col">
            {sortedTeams.map((team: any, index: any) => (
              <Box
                block
                onClick={() => {
                  if (selectedTeam?.data?._id !== team?._id) {
                    setTeam(team);
                    window.location.href = '/app/repositories';
                  }
                }}
                key={index}
                disabled={selectedTeam?.data?._id === team?._id}
                className={`flex items-center gap-2 hover:bg-[#3f4750]  px-4 py-3 ${
                  selectedTeam?.data?._id === team?._id
                    ? 'bg-[#3f4750] cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
              >
                <Avatar
                  className="mr-2"
                  src={team?.logo ? team?.logo : generateIdenticon(team?.name)}
                />
                <span className="text-sm">{team?.name}</span>
                <Label
                  className="ml-2"
                  variant={
                    team?.plan && team?.plan !== 'free'
                      ? 'success'
                      : !team?.plan
                      ? 'danger'
                      : 'primary'
                  }
                >
                  {team?.plan === 'build'
                    ? 'BUILD'
                    : team?.plan === 'pro' && team?.free_trial_status === 'active'
                    ? 'FREE TRIAL'
                    : team?.plan === 'pro'
                    ? 'DEPLOY'
                    : team?.plan === 'free'
                    ? 'FREE'
                    : !team?.plan || team?.plan === null
                    ? 'INACTIVE'
                    : 'ENTERPRISE'}
                </Label>
                {selectedTeam?.data?._id === team?._id ? (
                  <Check className="ml-auto" size={18} />
                ) : null}
              </Box>
            ))}
          </Box>
          {/* {selectedTeam?.data?.plan && selectedTeam?.data?.plan !== 'free' ? ( */}
          <span
            onClick={() => {
              navigate('/app/repositories?settings');
            }}
            className="text-sm border-t border-[#636C76] px-5 py-3 cursor-pointer hover:bg-[#3f4750]"
          >
            <span className="flex items-center gap-2">
              <Gear size={16} />
              <span>Organization Settings</span>
            </span>
          </span>
          {/* // ) : null} */}
        </Box>
      </ActionMenu.Overlay>
    </ActionMenu>
  );
};
