import create from 'zustand';

export type ProgressNotification = {
  id: string;
  message: string;
  title: string;
  progress: number;
  dismissed?: boolean;
};

type ProgressStore = {
  notifications: ProgressNotification[];
  addOrUpdate: (id: string, title: string, message: string, progress: number) => void;
  dismiss: (id: string) => void;
  remove: (id: string) => void;
};

export const useProgressStore = create<ProgressStore>((set) => ({
  notifications: [],
  addOrUpdate: (id, title, message, progress) => {
    set((state) => {
      // Check if notification with the same id already exists.
      const existingNotification = state.notifications.find((n) => n.id === id);
      // Preserve the dismissed flag if it exists; otherwise, default to false.
      const dismissed = existingNotification ? existingNotification.dismissed : false;
      const newNotification = { id, title, message, progress, dismissed };
      return {
        notifications: existingNotification
          ? state.notifications.map((n) => (n.id === id ? newNotification : n))
          : [...state.notifications, newNotification],
      };
    });

    // Auto-remove when progress completes (≥100%)
    if (progress >= 100) {
      setTimeout(() => {
        set((state) => ({
          notifications: state.notifications.filter((n) => n.id !== id),
        }));
      }, 2000);
    }
  },
  dismiss: (id) =>
    set((state) => ({
      notifications: state.notifications.map((n) => (n.id === id ? { ...n, dismissed: true } : n)),
    })),
  remove: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
    })),
}));
