import { useMutation, useQueryClient } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';
import { API_URL } from '@/config';

export const readAllNotifications = async (): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token) {
    return;
  }

  try {
    const response = await axios.patch(
      `${API_URL}/v1/notifications/read/all?access_token=${access_token}`
    );
    return response?.data;
  } catch (error) {}
};

export const useReadAllNotification = (config = {}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation<any, Error>({
    mutationFn: () => readAllNotifications(),
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to mark notifications as read',
        message: error.message,
      });
    },
    onSuccess: async (data) => {
      addNotification({
        type: 'success',
        title: 'All notifications are marked as read',
      });

      queryClient.invalidateQueries('notifications');
    },
    ...config,
  });
};
