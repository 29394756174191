import create from 'zustand';

export interface StreamNotification {
  id: any;
  message: string;
  type?: 'success' | 'error' | 'info';
  created_at?: any;
}

interface NotificationStore {
  notifications: StreamNotification[];
  addNotification: (notification: StreamNotification) => void;
  removeNotification: (id: any) => void;
  clearNotifications: () => void;
}

export const useStreamNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({ notifications: [...state.notifications, notification] })),
  removeNotification: (id: any) =>
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
    })),
  clearNotifications: () => set({ notifications: [] }),
}));
