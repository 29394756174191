import { Notification } from '@/components/Notifications/Notification';
import { useProgressStore } from '../stores/useProgressStore';

export const ProgressToastContainer = () => {
  // Only show notifications that are not dismissed in the toast container
  const notifications = useProgressStore((state) =>
    state.notifications.filter((n) => !n.dismissed)
  );
  return (
    <div
      aria-live="assertive"
      className="z-50 flex flex-col fixed inset-0 space-y-4 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      {notifications.map((n) => (
        <Notification
          key={n.id}
          notification={{
            id: n.id,
            type: 'info',
            title: n.title,
            message: n.message,
            delay: 0, // Disable auto-dismiss if needed
          }}
          // When dismissed, mark the notification as dismissed.
          onDismiss={() => useProgressStore.getState().dismiss(n.id)}
        />
      ))}
    </div>
  );
};
