import { useState, useEffect, useRef } from 'react';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

interface UseSSEOptions extends EventSourceInit {
  onMessage?: (event: MessageEvent) => void;
  onError?: (event: Event) => void;
}

interface SSEState<T> {
  data: T | null;
  error: string | null;
  isLoading: boolean;
}

export const useSSE = <T>(
  endpoint: string,
  options: UseSSEOptions = {},
  enabled: boolean = true,
  shouldClose: boolean = false, // Control closing manually
  isPublicStream: boolean = false,
  responseType: string = 'json',
  apiVersion?: string
): SSEState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const eventSourceRef = useRef<EventSource | null>(null);
  const reconnectTimerRef = useRef<NodeJS.Timeout | null>(null);

  const access_token = storage.getToken();
  const teamId = storage.getTeam()?._id;
  const apiUrl = API_URL + (apiVersion ? `/${apiVersion}` : '/v1');

  const url = isPublicStream
    ? `${apiUrl}${endpoint}`
    : `${apiUrl}${endpoint}?access_token=${access_token}&x_team=${teamId}`;

  // Handle errors and update state
  const handleError = (message: string) => {
    setError(message);
    setIsLoading(false);
  };

  // Function to establish the SSE connection
  const establishConnection = () => {
    if (!enabled || !endpoint) return;

    if (!isPublicStream && (!access_token || !teamId)) {
      if (!access_token) console.error('[SSE Connection] No access token found');
      if (!teamId) console.error('[SSE Connection] No team ID found');
      return;
    }

    // Close any previous connection
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const eventSource = new EventSource(url, options);
    eventSourceRef.current = eventSource;
    setIsLoading(true);

    eventSource.onmessage = (event: MessageEvent) => {
      if (options.onMessage) {
        options.onMessage(event);
      } else {
        try {
          const parsedData = responseType === 'json' ? JSON.parse(event.data) : event.data;
          setData(parsedData);
          setIsLoading(false);
        } catch (e) {
          handleError('Failed to parse event data');
        }
      }
    };

    eventSource.onerror = (event: Event) => {
      if (options.onError) {
        options.onError(event);
      } else {
        handleError('An error occurred with the SSE connection');
      }
      eventSource.close(); // Close the connection on error

      // Attempt to reconnect after 5 seconds
      reconnectTimerRef.current = setTimeout(establishConnection, 5000);
    };
  };

  useEffect(() => {
    if (enabled) {
      establishConnection();
    }

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
    };
  }, [endpoint, enabled, access_token, teamId]);

  useEffect(() => {
    if (shouldClose && eventSourceRef.current) {
      eventSourceRef.current.close();
      // Optionally clear timers if you want to stop reconnection attempts
      if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
    }
  }, [shouldClose]);

  return { data, error, isLoading };
};
