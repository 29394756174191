import { useMutation, useQueryClient } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';
import { API_URL } from '@/config';

export const readNotification = async ({
  notification_id,
}: {
  notification_id: any;
}): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token || !notification_id) {
    return;
  }

  try {
    const response = await axios.patch(
      `${API_URL}/v1/notifications/${notification_id}/read?access_token=${access_token}`
    );
    return response?.data;
  } catch (error) {}
};

export const useReadNotification = (config = {}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation<
    any,
    Error,
    {
      notification_id: any;
    }
  >({
    mutationFn: (notification_id) => readNotification(notification_id),
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to mark read',
        message: error.message,
      });
    },
    onSuccess: async (data) => {
      addNotification({
        type: 'success',
        title: 'Notification marked as read',
      });

      queryClient.invalidateQueries('notifications');
    },
    ...config,
  });
};
