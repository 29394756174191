import React, { useEffect, useState } from 'react';
import { Box, Tooltip } from '@primer/react';
import {
  ChartLineUp,
  Files,
  Graph,
  Gear,
  Info,
  PlayCircle,
  RocketLaunch,
  UserCircle,
} from 'phosphor-react';
import { UserNavigation } from '@/components/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoadingBar } from '@/context/LoadingBarContext';
import { NotificationDropdown } from '@/features/notifications';

interface LeftSidebarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  userDetails: any;
}

type SidebarItemProps = {
  icon: JSX.Element;
  activeIcon: JSX.Element;
  onClick: () => void;
  isActive: boolean;
  tooltip: string;
  onHover: (isHovered: boolean) => void;
  isHovered: boolean;
  disabled: boolean;
};

const SidebarItem = ({
  icon,
  activeIcon,
  onClick,
  isActive,
  tooltip,
  onHover,
  isHovered,
  disabled,
}: SidebarItemProps) => (
  <Tooltip aria-label={tooltip} direction="e">
    <div
      style={isActive ? { borderLeft: '2.5px solid white' } : {}}
      className="flex justify-center h-7 items-center"
      onMouseEnter={() => !disabled && onHover(true)}
      onMouseLeave={() => !disabled && onHover(false)}
    >
      <div
        onClick={disabled ? undefined : onClick}
        className={`flex justify-center ${
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        }`}
      >
        {isHovered || isActive ? activeIcon : icon}
      </div>
    </div>
  </Tooltip>
);

const sidebarItems = [
  {
    tabName: 'projectDetails',
    icon: <Info size={22} />,
    activeIcon: <Info size={22} weight="fill" />,
    tooltip: 'Project Info',
  },
  {
    tabName: 'editor',
    icon: <Graph size={22} />,
    activeIcon: <Graph weight="fill" size={22} />,
    tooltip: 'Workflow Editor',
  },
  {
    tabName: 'deployments',
    icon: <RocketLaunch size={22} />,
    activeIcon: <RocketLaunch size={22} weight="fill" />,
    tooltip: 'API Deployments',
  },
  {
    tabName: 'runs',
    icon: <PlayCircle size={22} />,
    activeIcon: <PlayCircle size={22} weight="fill" />,
    tooltip: 'Runs',
  },
  {
    tabName: 'settings',
    icon: <Gear size={22} />,
    activeIcon: <Gear size={22} weight="fill" />,
    tooltip: 'Settings',
  },
];

const LeftSidebar: React.FC<LeftSidebarProps> = ({ activeTab, setActiveTab, userDetails }) => {
  // const navigate = useNavigate();
  const location = useLocation();
  const [hoveredTab, setHoveredTab] = useState<string | null>(null);
  const { start, complete } = useLoadingBar();

  const { dummyProject } = location.state || {};

  const isTabDisabled = (tabName: string) => {
    return dummyProject && ['settings', 'deployments'].includes(tabName);
  };

  const handleTabChange = (tabName: string) => {
    start();
    setTimeout(() => {
      // navigate(`?${tabName}`);
      setActiveTab(tabName);
      complete();
    }, 1000); // 2 seconds delay
  };

  // useEffect(() => {
  //   const currentTab = location.search.slice(1); // Get the part after "?"
  //   if (currentTab && sidebarItems.some((item) => item.tabName === currentTab)) {
  //     setActiveTab(currentTab);
  //   }
  // }, [location.search, setActiveTab]);

  return (
    <Box className="flex flex-col h-full gap-4 bg-[#16191D]">
      <Box
        className="flex flex-col justify-between h-full pb-3 pt-3"
        sx={{ borderRight: '1px solid #343B45', width: '50px' }}
      >
        <Box className="flex flex-col gap-5">
          {sidebarItems.map((item, index) => (
            <SidebarItem
              key={index}
              icon={item.icon}
              activeIcon={item.activeIcon}
              disabled={isTabDisabled(item.tabName)}
              onClick={() => handleTabChange(item.tabName)}
              isActive={activeTab === item.tabName}
              tooltip={item.tooltip}
              onHover={(isHovered) => setHoveredTab(isHovered ? item.tabName : null)}
              isHovered={hoveredTab === item.tabName}
            />
          ))}
        </Box>
        <Box className="flex flex-col gap-6">
          <Tooltip aria-label={'Notifications'} direction="e">
            <Box className="pl-3">
              <NotificationDropdown />
            </Box>
          </Tooltip>
          <Box className={`pl-2 ${activeTab === 'profile' ? 'border-l-2' : ''}`}>
            <UserNavigation userDetails={userDetails} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSidebar;
