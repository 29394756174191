import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import {
  CaretDown,
  GithubLogo,
  GitFork,
  Lock,
  RocketLaunch,
  Spinner,
  ArrowLeft,
  Info,
  ArrowFatLinesUp,
  SquaresFour,
} from 'phosphor-react';
import logo from '@/assets/logo.svg';
import { Avatar, Box, Button, Token, ActionMenu, ActionList } from '@primer/react';
import { TeamsDropdown, useGetTeams } from '@/features/teams';
import { DropdownMorphed } from '../Elements/Dropdown';
import { useAppContext } from '@/context/AppContext';
import { PodsStatus, usePodStatus } from '@/features/pods';
import { useGetTeam } from '@/features/teams/api/getTeam';
import { generateIdenticon } from '@/utils/randomLogos';
import { UserNavigation } from './UserNavigation';
import CustomButton from '../Elements/Button/CustomButton';
import { useGetVolumeId } from '@/features/app';
import { NotificationDropdown } from '@/features/notifications';

export const TopBar = ({
  userDetails,
  repoDetails,
  userCredits,
}: {
  userDetails: any;
  repoDetails: any;
  userCredits: any;
}) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [isForked, setIsForked] = useState(false);

  const { projectId } = useParams<{ projectId: any }>();

  const { allPodsSSE } = usePodStatus(true);
  const { data: team, isLoading: isTeamLoading } = useGetTeam();
  const { data: teams, isLoading: isTeamsLoading } = useGetTeams();
  const { data: volume }: { data: any } = useGetVolumeId(team?.data?._id);

  const isUserTeamOwner =
    team?.data?.members?.find((member: any) => member?.role === 'owner')?.username ===
    userDetails?.login;

  const isUserTeamMember =
    team?.data?.members?.find((member: any) => member?.role === 'member')?.username ===
    userDetails?.login;

  const {
    isLoggedUserSameAsRepoUser,
    setRepoOwnerUserName,
    repoOwnerUserName,
    setLoggedInUsername,
    setRepoName,
    repo_name,
    loggedInUsername,
    setRepoUrl,
    repoUrl,
    setRepoBranchName,
    setIsDeploymentCheckModalOpen,
    activeTabModule,
    setUserVolumeId,
    setIsUserTeamOwner,
    setXTeamId,
    setAllPodsSSEData,
    setTeamApiData,
    setTeamsApiData,
    setRepoDescription,
    setIsRepoPrivate,
    setIsUserTeamMember,
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { dummyProject } = location.state || {};

  const showLogoWithFullName =
    location.pathname.includes('/app/repositories') &&
    !location.pathname.includes('/app/repositories/');
  const hideOnProfilePage = location.pathname.includes('/app/profile');

  useEffect(() => {
    setAllPodsSSEData(allPodsSSE);
  }, [allPodsSSE]);

  useEffect(() => {
    if (projectId && repoDetails) {
      setRepoName(repoDetails?.name);
      setRepoDescription(repoDetails?.description);
      setRepoOwnerUserName(repoDetails?.owner?.login);
      setRepoUrl(repoDetails?.html_url);
      setIsPrivate(repoDetails?.private);
      setIsRepoPrivate(repoDetails?.private);
      setIsForked(repoDetails?.fork);
      setRepoBranchName(repoDetails?.default_branch);
    }
  }, [repoDetails]);

  useEffect(() => {
    setXTeamId(team?.data?._id);
    setTeamApiData(team);
  }, [team]);

  useEffect(() => {
    setTeamsApiData(teams);
  }, [teams]);

  useEffect(() => {
    setIsUserTeamOwner(isUserTeamOwner);
    setIsUserTeamMember(isUserTeamMember);
    setLoggedInUsername(userDetails?.login);
    if (volume) {
      setUserVolumeId(volume?.data[0]?.volume_id);
    }
  }, [userDetails, volume, team]);

  const menuItems = [
    {
      icon: ArrowLeft,
      name: 'Back to Projects',
      url: '/app/repositories?workflows',
      newTab: false,
    },
    {
      icon: Info,
      name: 'Help',
      url: 'https://docs.flowscale.ai',
      newTab: true,
      // trailingLogo: ArrowSquareOut,
    },
  ];

  const handleDeployButtonClick = () => {
    setIsDeploymentCheckModalOpen(true);
  };

  const getDifferingGPUStatus = () => {
    if (!allPodsSSE || allPodsSSE.length === 0) return 'No pods found';
    const differingGPUStatuses = allPodsSSE
      .flatMap((pod: any) =>
        pod.clusters.map((cluster: any) => {
          const gpuStatuses = cluster?.containers
            .filter(
              (container: any) => container?.gpu !== 'CPU' && container?.gpu_status !== undefined
            )
            .map((container: any) => container?.gpu_status);

          // console.log('[DEBUG] gpuStatuses', gpuStatuses);

          const differingStatus = gpuStatuses.find(
            (status: any, _: any, arr: any) => status !== arr[0]
          );

          return {
            podName: pod.name,
            clusterName: cluster.name || `Cluster ${cluster._id}`,
            gpuStatus: differingStatus || gpuStatuses[0],
          };
        })
      )
      .filter((status: any) => status.gpuStatus !== undefined);

    // Prioritize running state
    differingGPUStatuses.sort((a: any, b: any) => {
      if (a.gpuStatus === 'running') return -1;
      if (b.gpuStatus === 'running') return 1;
      return 0;
    });

    return differingGPUStatuses;
  };

  const differingGPUStatuses = getDifferingGPUStatus();

  // console.log('[DEBUG] differingGPUStatuses', differingGPUStatuses);

  return (
    <div
      className={`${
        showLogoWithFullName ? 'px-[120px]' : 'px-2'
      } relative py-2 flex justify-between w-full border-b border-[#343B45]`}
    >
      <Box className="flex items-center w-full justify-center gap-2">
        {showLogoWithFullName ? (
          <Box className="flex items-center justify-between w-full">
            <img
              src={LogoWithFullName}
              onClick={() => navigate('/app/repositories')}
              className="h-6 cursor-pointer"
              alt="logo"
            />
            <Box className="flex items-center gap-4">
              {team?.data?.plan === 'free' && (
                <div className="flex rounded-3xl bg-gradient-to-r from-[#1659da] to-[#16CFDA] p-0.5">
                  <button
                    onClick={() =>
                      navigate('/app/repositories?settings', { state: { tab: 'Billing & Plans' } })
                    }
                    className="flex gap-2 items-center hover:bg-transparent bg-[#121417] text-white justify-center text-xs px-4 py-1.5 rounded-3xl"
                  >
                    <ArrowFatLinesUp size={16} />
                    Upgrade Plan
                  </button>
                </div>
              )}

              <TeamsDropdown teams={teams} isTeamsLoading={isTeamsLoading} />
              <DropdownMorphed
                status={differingGPUStatuses?.[0]?.gpuStatus}
                credits={userCredits?.credits?.toFixed(2)}
                creditsLoading={!userCredits}
              >
                <PodsStatus allPodsSSE={allPodsSSE} />
              </DropdownMorphed>

              <NotificationDropdown />

              <UserNavigation
                userDetails={userDetails}
                menuClassName="top-8 left-0 z-[40] bg-[#272b31]"
              />
            </Box>
          </Box>
        ) : (
          <Box className="flex items-center justify-between w-full">
            <Box className="flex items-center gap-3">
              <ActionMenu>
                <ActionMenu.Button
                  size="small"
                  trailingAction={CaretDown}
                  sx={{ backgroundColor: '#22262C', height: '35px' }}
                >
                  <img src={logo} alt="logo" />
                </ActionMenu.Button>

                <ActionMenu.Overlay>
                  <ActionList
                    sx={{
                      padding: '12px 8px',
                    }}
                  >
                    {menuItems.map((item, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          if (item.newTab) {
                            window.open(item.url, '_blank');
                          } else {
                            navigate(item.url);
                          }
                        }}
                        className="flex rounded-md justify-between w-full items-center gap-2 py-2 px-3 hover:bg-gray-700 hover:text-white cursor-pointer text-xs"
                      >
                        <Box className="flex items-center gap-2">
                          {item.icon && <item.icon size={18} />}
                          {item.name}
                        </Box>
                        {/* {item.trailingLogo && <item.trailingLogo size={18} />} */}
                      </Box>
                    ))}
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>
            </Box>
            <Box
              className="flex justify-center items-center gap-3 absolute"
              sx={{
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {isTeamLoading ? (
                <Spinner size={18} className="animate-spin" />
              ) : (
                <ol className="flex items-center">
                  <li>
                    <Link
                      to={
                        dummyProject ? '/app/repositories?explore' : '/app/repositories?workflows'
                      }
                    >
                      <Box className="flex items-center gap-1">
                        {dummyProject &&
                        team?.data?.plan !== 'free' &&
                        team?.data?.free_trial_status !== 'completed' ? (
                          <SquaresFour size={18} />
                        ) : (
                          <Avatar
                            className="mr-1 border border-gray-700"
                            size={26}
                            src={
                              team?.data?.logo
                                ? team?.data?.logo
                                : generateIdenticon(team?.data?.name)
                            }
                          />
                        )}
                        <span className="hover:border-b">
                          {dummyProject &&
                          team?.data?.plan !== 'free' &&
                          team?.data?.free_trial_status !== 'completed'
                            ? 'Explore'
                            : team?.data?.name}
                        </span>
                      </Box>
                    </Link>
                  </li>
                  <li className="mx-2">/</li>
                  <li>
                    <p aria-current="page">{hideOnProfilePage ? 'profile' : repo_name}</p>
                  </li>
                </ol>
              )}
              {isPrivate && (
                <div
                  style={{
                    borderRadius: '24px',
                    padding: '6px 12px',
                    color: '#D7D7DB',
                    backgroundColor: '#343B45',
                    fontSize: '12px',
                  }}
                >
                  <Lock />
                </div>
              )}
              {isForked && (
                <Token
                  leadingVisual={GitFork}
                  sx={{
                    borderRadius: '24px',
                    padding: '12px 14px',
                    color: '#D7D7DB',
                    backgroundColor: '#343B45',
                    fontSize: '12px',
                  }}
                  text={'Forked'}
                />
              )}
              {repoOwnerUserName && (
                <Button
                  leadingVisual={GithubLogo}
                  sx={{
                    border: '1px solid #636C76',
                    borderRadius: '24px',
                    color: '#D7D7DB',
                    fontSize: '12px',
                    backgroundColor: 'transparent',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(repoUrl, '_blank');
                  }}
                >
                  View on Github
                </Button>
              )}
              {/* {dummyProject && (
                <Button
                  leadingVisual={GitFork}
                  sx={{
                    border: '1px solid #636C76',
                    borderRadius: '24px',
                    color: '#D7D7DB',
                    fontSize: '12px',
                    backgroundColor: 'transparent',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleForkRepository();
                  }}
                >
                  Fork
                </Button>
              )} */}
            </Box>

            {!hideOnProfilePage && (
              <Box className="flex items-center gap-3">
                <DropdownMorphed
                  status={differingGPUStatuses?.[0]?.gpuStatus}
                  credits={userCredits?.credits?.toFixed(2)}
                  creditsLoading={!userCredits}
                >
                  <PodsStatus allPodsSSE={allPodsSSE} />
                </DropdownMorphed>

                {!dummyProject && activeTabModule === 'editor' && team?.data?.plan !== 'free' ? (
                  <CustomButton
                    leadingVisual={RocketLaunch}
                    style={{
                      backgroundColor: '#fff',
                      color: '#16191D',
                      padding: '4px 11px',
                      fontSize: '14px',
                    }}
                    hoverStyle={{
                      color: '#C4C4CA',
                      border: '1px solid #636C76',
                      backgroundColor: '#16191D',
                      fontSize: '14px',
                    }}
                    onClick={handleDeployButtonClick}
                  >
                    Deploy
                  </CustomButton>
                ) : (
                  team?.data?.plan === 'free' && (
                    <div className="flex rounded-3xl bg-gradient-to-r from-[#1659da] to-[#16CFDA] p-0.5">
                      <button
                        onClick={() =>
                          navigate('/app/repositories?settings', {
                            state: { tab: 'Billing & Plans' },
                          })
                        }
                        className="flex gap-2 items-center hover:bg-transparent bg-[#121417] text-white justify-center text-xs px-4 py-1.5 rounded-3xl"
                      >
                        <ArrowFatLinesUp size={16} />
                        Upgrade Plan
                      </button>
                    </div>
                  )
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>

      {/* {!isLoggedUserSameAsRepoUser && !showLogoWithFullName && !hideOnProfilePage && (
            <Button
              leadingVisual={GitFork}
              sx={{
                border: '1px solid #636C76',
                padding: '8px, 16px, 8px, 16px',
                borderRadius: '24px',
                color: '#D7D7DB',
                fontSize: '14px',
                backgroundColor: 'transparent',
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleForkRepository();
              }}
            >
              Fork this repository
            </Button>
          )} */}
    </div>
  );
};
