import React, { useState, useEffect } from 'react';
import { Box } from '@primer/react';
import { Code, Monitor, MonitorPlay, XCircle } from 'phosphor-react';
import CustomBlankslate from '@/components/Elements/CustomBlankSlate/CustomBlankSlate';

interface Container {
  _id: string;
  gpu_status: string;
  gpu: string;
}

interface Cluster {
  _id: string;
  status: string;
  mode: string;
  containers: Container[];
}

interface Pod {
  _id: string;
  name: string;
  clusters: Cluster[];
}

interface PodsStatusProps {
  allPodsSSE: Pod[];
}

export const PodsStatus: React.FC<PodsStatusProps> = ({ allPodsSSE }) => {
  const [podsState, setPodsState] = useState<Pod[]>([]);

  // Deep compare two objects
  const deepCompare = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!deepCompare(allPodsSSE, podsState)) {
      setPodsState(allPodsSSE);
    }
  }, [allPodsSSE, podsState]);

  // Get container counts for a specific mode (api, comfyui, etc.)
  const getContainersStatusCount = (clusters: Cluster[], mode: string) => {
    const filteredClusters = clusters.filter((cluster) => cluster.mode === mode);
    const containers = filteredClusters.flatMap((cluster) => cluster.containers || []);
    const validContainers = containers.filter((container) => container.gpu);
    const idleCount = validContainers.filter((container) => container.gpu_status === 'idle').length;
    const runningCount = validContainers.filter(
      (container) =>
        container.gpu_status === 'running' ||
        container.gpu_status === 'initializing' ||
        container.gpu_status === 'cold-starting'
    ).length;
    const stoppedCount = validContainers.filter((container) => container.gpu_status === 'stopped').length;

    return { idle: idleCount, running: runningCount, stopped: stoppedCount };
  };

  // Check if any container is running for a given mode
  const isAnyContainerRunning = (clusters: Cluster[], mode: string) => {
    const filteredClusters = clusters.filter((cluster) => cluster.mode === mode);
    const containers = filteredClusters.flatMap((cluster) => cluster.containers || []);
    const validContainers = containers.filter((container) => container.gpu);
    return validContainers.some(
      (container) =>
        container.gpu_status === 'running' ||
        container.gpu_status === 'initializing' ||
        container.gpu_status === 'cold-starting'
    );
  };

  /**
   * Displays container status dots.
   * For mode 'api' (or default), shows up to 10 dots (in order: running, idle, stopped)
   * with any extra containers indicated as "+N".
   * For mode 'comfyui', always displays just one dot.
   */
  const getContainersDisplay = (
    idleCount: number,
    runningCount: number,
    stoppedCount: number,
    mode: 'api' | 'comfyui' = 'api'
  ) => {
    if (mode === 'comfyui') {
      // For ComfyUI, show a single dot if there's any container.
      const total = idleCount + runningCount + stoppedCount;
      if (total > 0) {
        // Determine dot color based on status priority: running > idle > stopped.
        let dotClasses = 'h-2 w-2 rounded-full border border-gray-500 bg-gray-500';
        if (runningCount > 0) {
          dotClasses = 'h-2 w-2 rounded-full border border-green-500 bg-green-500';
        } else if (idleCount > 0) {
          dotClasses = 'h-2 w-2 rounded-full border border-yellow-500 bg-yellow-500';
        }
        return (
          <div className="flex items-center ml-2 gap-0.5">
            <span className={dotClasses}></span>
          </div>
        );
      } else {
        return null;
      }
    } else {
      // For API Server (or default mode), show up to 10 dots.
      const maxDots = 10;
      const totalContainers = idleCount + runningCount + stoppedCount;

      // Order: running, then idle, then stopped.
      const runningDots = Math.min(runningCount, maxDots);
      let remainingDots = maxDots - runningDots;
      const idleDots = Math.min(idleCount, remainingDots);
      remainingDots -= idleDots;
      const stoppedDots = Math.min(stoppedCount, remainingDots);
      const displayedDots = runningDots + idleDots + stoppedDots;
      const extraDots = totalContainers > displayedDots ? totalContainers - displayedDots : 0;

      return (
        <div className="flex items-center ml-2 gap-0.5">
          {Array.from({ length: runningDots }).map((_, index) => (
            <span
              key={`running-${index}`}
              className="h-2 w-2 rounded-full border border-green-500 bg-green-500"
            ></span>
          ))}
          {Array.from({ length: idleDots }).map((_, index) => (
            <span
              key={`idle-${index}`}
              className="h-2 w-2 rounded-full border border-yellow-500 bg-yellow-500"
            ></span>
          ))}
          {Array.from({ length: stoppedDots }).map((_, index) => (
            <span
              key={`stopped-${index}`}
              className="h-2 w-2 rounded-full border border-gray-500 bg-gray-500"
            ></span>
          ))}
          {extraDots > 0 && <span className="text-xs text-gray-500">+{extraDots}</span>}
        </div>
      );
    }
  };

  return (
    <Box className="flex flex-col">
      {podsState.length === 0 ? (
        <Box className="flex justify-center items-center my-7 h-full w-full">
          <CustomBlankslate
            icon={<XCircle size={34} />}
            heading="No pods found"
            description="There are no pods available."
          />
        </Box>
      ) : (
        podsState.map((pod, index) => {
          const apiStatus = getContainersStatusCount(pod.clusters, 'api');
          const comfyUIStatus = getContainersStatusCount(pod.clusters, 'comfyui');
          const isApiRunning = isAnyContainerRunning(pod.clusters, 'api');
          const isComfyUIRunning = isAnyContainerRunning(pod.clusters, 'comfyui');
          const isAnyRunning = isApiRunning || isComfyUIRunning;

          return (
            <Box
              key={pod._id}
              className={`p-4 ${index !== podsState.length - 1 ? 'border-b border-gray-700' : ''}`}
            >
              <h3 className="font-bold text-xs flex items-center">
                {isAnyRunning ? (
                  <span className="h-2 w-2 rounded-full bg-green-500 mr-2"></span>
                ) : (
                  <Monitor size={18} className="mr-2" />
                )}
                {pod.name}
              </h3>

              {/* Clusters and Containers Status */}
              <Box className="mt-3 text-xs">
                {/* ComfyUI Workspace Status */}
                <Box className="flex items-center gap-2 mt-2">
                  <span className="text-xs flex items-center border bg-[#16191D] border-gray-700 px-3 py-1 rounded-xl">
                    <MonitorPlay size={14} className="mr-2" />
                    ComfyUI Workspace
                    {getContainersDisplay(
                      comfyUIStatus.idle,
                      comfyUIStatus.running,
                      comfyUIStatus.stopped,
                      'comfyui'
                    )}
                  </span>
                </Box>

                {/* API Server Status */}
                <Box className="flex items-center gap-2 mt-2">
                  <span className="text-xs flex items-center border bg-[#16191D] border-gray-700 px-3 py-1 rounded-xl">
                    <Code size={14} className="mr-2" />
                    API Server
                    {getContainersDisplay(apiStatus.idle, apiStatus.running, apiStatus.stopped)}
                  </span>
                </Box>
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
};
