import { useState, useEffect, useRef } from 'react';
import { API_URL } from '@/config';
import storage from '@/utils/storage';
import { useAppContext } from '@/context/AppContext';

export const usePodStatus = (enabled: boolean) => {
  const [allPodsSSE, setAllPods] = useState<any[]>([]);
  // Use refs to hold the current EventSource and timers
  const eventSourceRef = useRef<EventSource | null>(null);
  const reconnectTimerRef = useRef<NodeJS.Timeout | null>(null);
  const access_token = storage.getToken();
  const { xTeamId: xTeamIdFromTeamApi } = useAppContext();

  const establishSSEConnection = () => {
    const teamData = localStorage.getItem('flowscale_hub_team');
    const xTeamIdFromLocalStorage = teamData ? JSON.parse(teamData)._id : null;
    const xTeamId = xTeamIdFromTeamApi || xTeamIdFromLocalStorage;
    if (!enabled || !access_token || !xTeamId) return;

    // Close any existing connection using the ref
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const url = `${API_URL}/v2/pod/status/stream?access_token=${access_token}&x_team=${xTeamId}`;
    const newEventSource = new EventSource(url);
    eventSourceRef.current = newEventSource;

    newEventSource.onopen = () => {
      // Clear any pending reconnect timer on a successful connection
      if (reconnectTimerRef.current) {
        clearTimeout(reconnectTimerRef.current);
        reconnectTimerRef.current = null;
      }
    };

    newEventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setAllPods((prevPods) => {
        const existingIndex = prevPods.findIndex((pod) => pod._id === data._id);
        if (existingIndex !== -1) {
          const updatedPods = [...prevPods];
          updatedPods[existingIndex] = data;
          return updatedPods;
        }
        return [...prevPods, data];
      });
    };

    newEventSource.onerror = () => {
      newEventSource.close();
      // Schedule a reconnect after 5 seconds
      reconnectTimerRef.current = setTimeout(establishSSEConnection, 5000);
    };
  };

  useEffect(() => {
    if (enabled) {
      establishSSEConnection();
    } else {
      // On disable, clean up the connection and timers
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
      if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
    }

    return () => {
      // Cleanup on unmount or dependency change
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
    };
  }, [enabled]);

  const terminateSSEConnection = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    if (reconnectTimerRef.current) clearTimeout(reconnectTimerRef.current);
  };

  return { allPodsSSE, establishSSEConnection, terminateSSEConnection };
};
