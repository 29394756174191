import { useEffect } from 'react';
import { useNotificationStream } from '../hooks/useNotificationStream';
import { useStreamNotificationStore } from '../stores/useStreamNotificationStore';
import { useProgressStore } from '../stores/useProgressStore';

export const NotificationStreamProvider = () => {
  const { addNotification: addStreamNotification } = useStreamNotificationStore();
  // Add `removeProgressNotification` from the store [[10]]
  const { addOrUpdate: addProgressNotification, remove: removeProgressNotification } =
    useProgressStore();
  const { notification } = useNotificationStream(true);

  useEffect(() => {
    if (!notification) return;

    const notificationsArray = Array.isArray(notification) ? notification : [notification];

    notificationsArray.forEach((n) => {
      const progressMatch = n?.message?.match(/(\d+\.?\d*)%/);
      if (progressMatch) {
        const fileId = n?.data?.file_id;
        const modelMatch = n?.message?.match(/Model upload progress for (.*?):/);
        const modelName = modelMatch ? modelMatch[1] : 'unknown';
        const progressId = fileId ? `model_upload_${fileId}` : `model_upload_${modelName}`;
        const progress = parseFloat(progressMatch[1]);
        addProgressNotification(progressId, n?.title, n?.message, progress);
      } else {
        // Handle failure notifications [[5]]
        if (n?.type === 'model_upload' && n?.title === 'Model Upload Failed' && n?.data?.file_id) {
          const fileId = n.data.file_id;
          const progressId = `model_upload_${fileId}`;
          removeProgressNotification(progressId); // Remove associated progress [[10]]
        }

        if (!n?._id) return;
        const transformedNotification = {
          id: n?._id,
          message: n?.message,
          read: false,
          created_at: n?.created_at,
        };
        addStreamNotification(transformedNotification);
      }
    });
  }, [notification]);

  return null;
};
