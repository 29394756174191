import { default as dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

// This function will convert the GMT time from the API to the user's local time
export const formatToLocalTime = (dateString: string): string => {
  const date = dayjs.utc(dateString).local();
  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date.toDate());
};

export const formatDate = (date: number | string) => dayjs(date).format('MMMM D, YYYY h:mm A');

export const showDay = (dateString: string): string => {
  return dayjs(dateString).format('MMMM D, YYYY');
};

export const showNextDay = (dateString: string): string => {
  return dayjs(dateString).add(1, 'day').format('MMMM D, YYYY');
};

export const showNotificationDate = (dateString: string): string => {
  const date = dayjs.utc(dateString).local();
  const yesterdayStart = dayjs().subtract(1, 'day').startOf('day');
  
  // If the notification occurred after the start of yesterday, show relative time.
  if (date.isAfter(yesterdayStart)) {
    return date.fromNow();
  } else {
    return date.format('MMMM D, YYYY');
  }
};