import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';
import { API_URL } from '@/config';

export const getAllNotifications = async (
  xTeamId: string,
  skip: number = 1,
  limit: number = 25
): Promise<any> => {
  const access_token = storage.getToken();

  if (!access_token || !xTeamId) {
    return;
  }

  try {
    const response = await axios.get(
      `${API_URL}/v1/notifications?skip=${skip}&limit=${limit}&access_token=${access_token}`,
      {
        headers: {
          'X-Team': xTeamId,
        },
      }
    );
    return response;
  } catch (error) {}
};

export const useGetAllNotifications = (
  xTeamId: string,
  skip: number,
  limit: number,
  config = {}
) => {
  const { addNotification } = useNotificationStore();
  return useQuery<any, Error>({
    queryKey: ['notifications', skip, limit],
    queryFn: () => getAllNotifications(xTeamId, skip, limit),
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Failed to get notifications',
        message: "We couldn't fetch the notifications. Please try again.",
      });
    },
    ...config,
  });
};
